.ui-group-buttons .or {
  position: relative;
  float: left;
  width: .3em;
  height: 1.3em;
  z-index: 3;
  font-size: 12px; }

.ui-group-buttons .or:before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: 'or';
  background-color: #5a5a5a;
  margin-top: -.1em;
  margin-left: -.9em;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.55;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  border-radius: 500px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

.ui-group-buttons .or:after {
  position: absolute;
  top: 0;
  left: 0;
  content: ' ';
  width: .3em;
  height: 2.84em;
  background-color: transparent;
  border-top: .6em solid #5a5a5a;
  border-bottom: .6em solid #5a5a5a; }

.ui-group-buttons .or.or-lg {
  height: 1.3em;
  font-size: 16px; }

.ui-group-buttons .or.or-lg:after {
  height: 2.85em; }

.ui-group-buttons .or.or-sm {
  height: 1em; }

.ui-group-buttons .or.or-sm:after {
  height: 2.5em; }

.ui-group-buttons .or.or-xs {
  height: .25em; }

.ui-group-buttons .or.or-xs:after {
  height: 1.84em;
  z-index: -1000; }

.ui-group-buttons {
  display: inline-block;
  vertical-align: middle; }

.ui-group-buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.ui-group-buttons .btn {
  float: left;
  border-radius: 0; }

.ui-group-buttons .btn:first-child {
  margin-left: 0;
  border-top-left-radius: .25em;
  border-bottom-left-radius: .25em;
  padding-right: 15px; }

.ui-group-buttons .btn:last-child {
  border-top-right-radius: .25em;
  border-bottom-right-radius: .25em;
  padding-left: 15px; }

.icon-circle .fa {
  font-size: 25px;
  color: #e84700;
  margin: 0 auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #e84700;
  line-height: 30px;
  cursor: pointer;
  -webkit-transition: all  ease-in-out 0.35s;
  -moz-transition: all  ease-in-out 0.35s;
  -o-transition: all  ease-in-out 0.35s;
  -ms-transition: all  ease-in-out 0.35s;
  transition: all  ease-in-out 0.20s,background-color ease-in-out 0.05s; }

.icon-circle .fa:hover {
  background-color: #e84700;
  color: #fff;
  border: 1px solid #e84711;
  -moz-box-shadow: inset 0px 0px 0px 5px #ffffff;
  -o-box-shadow: inset 0px 0px 0px 5px #ffffff;
  -ms-box-shadow: inset 0px 0px 0px 5px #ffffff;
  -webkit-box-shadow: inset 0px 0px 0px 5px #ffffff;
  box-shadow: inset 0px 0px 0px 5px #ffffff;
  -ms-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2); }

.icon-circle i:before {
  margin-left: 0px;
  font-size: 15px; }

@media (min-width: 320px) and (max-width: 768px) {
  .icon-circle .fa {
    font-size: 15px;
    color: #e84700;
    margin: 0 auto;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid #e84700;
    line-height: 40px;
    cursor: pointer;
    -webkit-transition: all  ease-in-out 0.35s;
    -moz-transition: all  ease-in-out 0.35s;
    -o-transition: all  ease-in-out 0.35s;
    -ms-transition: all  ease-in-out 0.35s;
    transition: all  ease-in-out 0.20s,background-color ease-in-out 0.05s; }
  .icon-circle .fa:hover {
    background-color: #e84700;
    color: #fff;
    border: 1px solid #e84711;
    -moz-box-shadow: inset 0px 0px 0px 5px #ffffff;
    -o-box-shadow: inset 0px 0px 0px 5px #ffffff;
    -ms-box-shadow: inset 0px 0px 0px 5px #ffffff;
    -webkit-box-shadow: inset 0px 0px 0px 5px #ffffff;
    box-shadow: inset 0px 0px 0px 5px #ffffff;
    -ms-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2); }
  .icon-circle i:before {
    margin-left: 0px;
    font-size: 20px; } }

.ifacebook .fa {
  color: #3B5998;
  border: 2px solid #3B5998; }

.ifacebook .fa:hover {
  background-color: #3B5998;
  color: #fff;
  border: 1px solid #3B5998; }

.itwittter .fa {
  color: #33ccff;
  border: 2px solid #33ccff; }

.itwittter .fa:hover {
  background-color: #33ccff;
  color: #fff;
  border: 1px solid #33ccff; }

.igoogle .fa {
  color: #BD3518;
  border: 2px solid #BD3518; }

.igoogle .fa:hover {
  background-color: #BD3518;
  color: #fff;
  border: 1px solid #BD3518; }

.iLinkedin .fa {
  color: #007bb7;
  border: 2px solid #007bb7; }

.iLinkedin .fa:hover {
  background-color: #007bb7;
  color: #fff;
  border: 1px solid #007bb7; }

.pagination {
  display: inline-block; }

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

.pagination > li > a, .pagination > li > span {
  position: relative;
  font-size: 16px;
  float: left;
  padding: 6px 12px;
  height: 35px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.badgebox {
  opacity: 0; }

.badgebox + .badge {
  text-indent: -999999px;
  width: 27px; }

.badgebox:focus + .badge {
  box-shadow: inset 0px 0px 5px; }

.badgebox:checked + .badge {
  text-indent: 0; }

.btn-primary {
  color: #fff;
  background-color: #567c07;
  border-color: #567c07; }
  .btn-primary:hover {
    background: #354c04; }

.btn-secondary {
  color: #567c07;
  background-color: #b1f329;
  border-color: transparent; }

/*==============================*/
/*====== siderbar user profile =====*/
/*==============================*/
.profile {
  /*==============================*/
  /*====== Social Profile css =====*/
  /*==============================*/
  /*==============================*/
  /*====== Recently connected  heading =====*/
  /*==============================*/ }
  .profile .nav > li > a.userdd {
    padding: 5px 15px; }
  .profile p {
    color: #000;
    padding-top: 10px;
    margin-bottom: 8px; }
    .profile p.lead {
      font-size: 12px;
      margin-bottom: 5px;
      padding-top: 0; }
      .profile p.lead a.category {
        color: #000;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 8px; }
  .profile .panel-heading h1 {
    font-size: 20px;
    margin: 5px;
    font-weight: bold; }
    .profile .panel-heading h1.title {
      font-size: 20px;
      font-weight: bold; }
  .profile .userprofile {
    width: 100%;
    float: left;
    clear: both;
    text-align: center;
    margin: 20px auto; }
  .profile .userprofile .userpic {
    height: 100px;
    width: 100px;
    object-fit: cover;
    clear: both;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative; }
  .profile .userprofile .userpic .userpicimg {
    height: auto;
    width: 100%;
    border-radius: 100%; }
  .profile .username {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .profile .username + p {
    color: #607d8b;
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .profile .settingbtn {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15); }
  .profile .userprofile.small {
    width: auto;
    clear: both;
    margin: 0px auto; }
  .profile .userprofile.small .userpic {
    height: 40px;
    width: 40px;
    margin: 0 10px 0 0;
    display: block;
    border-radius: 100%;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    float: left; }
  .profile .userprofile.small .textcontainer {
    float: left;
    max-width: 100px;
    padding: 0; }
  .profile .userprofile.small .userpic .userpicimg {
    min-height: 100%;
    width: 100%;
    border-radius: 100%; }
  .profile .userprofile.small .username {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin: 0px;
    float: left;
    width: 100%; }
  .profile .userprofile.small .username + p {
    color: #607d8b;
    font-size: 13px;
    float: left;
    width: 100%;
    margin: 0; }
  .profile .countlist h3 {
    margin: 0;
    font-weight: 400;
    line-height: 28px; }
  .profile .countlist {
    text-transform: uppercase;
    padding: 0 30px; }
  .profile .countlist li {
    padding: 15px 50px 15px 0;
    font-size: 14px; }
  .profile .countlist li small {
    font-size: 12px;
    margin: 0; }
  .profile .countlist a {
    color: #fff; }
  .profile .followbtn {
    float: right;
    margin: 22px 10px; }
  .profile .userprofile.social {
    background: url(http://www.bootdey.com/img/Content/flores-amarillas-wallpaper.jpeg) no-repeat top center;
    background-size: 100%;
    padding: 50px 0;
    margin: 0; }
  .profile .userprofile.social .username {
    color: #ffffff; }
  .profile .userprofile.social .username + p {
    color: #ffffff;
    opacity: 0.8; }
  .profile .postbtn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9; }
  .profile .status-upload {
    width: 100%;
    float: left;
    margin-bottom: 15px; }
  .profile .posttimeline .panel {
    margin-bottom: 15px; }
  .profile .commentsblock {
    background: #f8f9fb; }
  .profile .nopaddingbtm {
    margin-bottom: 0; }
  .profile .memberblock {
    width: 100%;
    float: left;
    clear: both;
    margin-bottom: 15px; }
  .profile .member {
    width: 24%;
    float: left;
    margin: 2px 1% 2px 0;
    background: #ffffff;
    border: 1px solid #d8d0c3;
    padding: 3px;
    position: relative;
    overflow: hidden; }
  .profile .memmbername {
    position: absolute;
    bottom: -30px;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    line-height: 30px;
    padding: 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 11px;
    transition: 0.5s ease all; }
  .profile .member:hover .memmbername {
    bottom: 0; }
  .profile .member img {
    width: 100%;
    transition: 0.5s ease all; }
  .profile .member:hover img {
    opacity: 0.8;
    transform: scale(1.2); }
  .profile .panel-default > .panel-heading {
    color: #607D8B;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    padding: 20px;
    border-radius: 0;
    border-color: #e1eaef; }
    .profile .panel-default > .panel-heading.profile {
      font-size: 20px;
      font-weight: bold; }
  .profile .panel-body {
    margin-bottom: 20px; }
    .profile .panel-body .page-subtitle {
      padding: 5px 20px; }
  .profile .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429; }
  .profile .page-header.small {
    position: relative;
    line-height: 22px;
    font-weight: 400;
    font-size: 20px; }
  .profile .favorite i {
    color: #eb3147; }
  .profile .btn i {
    font-size: 17px; }
  .profile .panel {
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    margin-bottom: 35px;
    border-radius: 0px;
    position: relative;
    border: 0;
    display: inline-block;
    width: 100%; }
  .profile .panel-footer {
    padding: 10px 15px;
    background-color: #ffffff;
    border-top: 1px solid #eef2f4;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: #607d8b; }
  .profile .panel-blue {
    color: #ffffff;
    background-color: #03A9F4; }
  .profile .panel-red.userlist .username, .profile .panel-green.userlist .username, .profile .panel-yellow.userlist .username, .profile .panel-blue.userlist .username {
    color: #ffffff; }
  .profile .panel-red.userlist p, .profile .panel-green.userlist p, .profile .panel-yellow.userlist p, .profile .panel-blue.userlist p {
    color: rgba(255, 255, 255, 0.8); }
  .profile .panel-red.userlist p a, .profile .panel-green.userlist p a, .profile .panel-yellow.userlist p a, .profile .panel-blue.userlist p a {
    color: rgba(255, 255, 255, 0.8); }
  .profile .progress-bar-success, .profile .status.active, .profile .panel-green, .profile .panel-green > .panel-heading, .profile .btn-success, .profile .fc-event, .profile .badge.green, .profile .event_green {
    color: white;
    background-color: #8BC34A; }
  .profile .progress-bar-warning, .profile .panel-yellow, .profile .status.pending, .profile .panel-yellow > .panel-heading, .profile .btn-warning, .profile .fc-unthemed .fc-today, .profile .badge.yellow, .profile .event_yellow {
    color: white;
    background-color: #FFC107; }
  .profile .progress-bar-danger, .profile .panel-red, .profile .status.inactive, .profile .panel-red > .panel-heading, .profile .btn-danger, .profile .badge.red, .profile .event_red {
    color: white;
    background-color: #F44336; }
  .profile .media-object {
    width: 100%;
    height: 320px;
    object-fit: cover;
    margin: 10px 0; }
  .profile .media:first-child {
    margin-top: 15px; }
  .profile .media-object {
    display: block; }
  .profile .media-body {
    margin-left: 20px;
    padding-bottom: 20px; }
    .profile .media-body h2 {
      font-size: 18px;
      font-weight: bold; }
  .profile .dotbtn {
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
    line-height: 40px;
    vertical-align: middle;
    padding: 0;
    margin-right: -15px; }
  .profile .dots {
    height: 4px;
    width: 4px;
    position: relative;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin: 0 auto; }
  .profile .dots:after, .profile .dots:before {
    content: " ";
    height: 4px;
    width: 4px;
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    top: -7px;
    left: 0; }
  .profile .dots:after {
    content: " ";
    top: auto;
    bottom: -7px;
    left: 0; }
  .profile .photolist img {
    width: 100%; }
  .profile .photolist {
    background: #e1eaef;
    padding-top: 15px;
    padding-bottom: 15px; }
  .profile .profilegallery .grid-item a {
    height: 100%;
    display: block; }
  .profile .grid a {
    width: 100%;
    display: block;
    float: left; }
  .profile .media-body {
    color: #607D8B;
    overflow: visible; }
  .profile .media-heading a {
    font-size: 18px; }

@media screen and (max-width: 768px) {
  .profile.modifier {
    margin: -20px; }
    .profile.modifier h1 {
      padding-left: 15px;
      padding-right: 10px; }
    .profile.modifier h2 {
      padding-left: 10px;
      padding-right: 10px; }
    .profile.modifier p {
      padding-left: 10px;
      padding-right: 10px; }
  .profile.userpage_modifier h2 {
    padding-left: 10px;
    padding-right: 10px; }
  .profile.userpage_modifier p {
    padding-left: 10px;
    padding-right: 10px; }
  .profile h1 {
    padding-left: 5px; }
  .show_category {
    padding: 5px; } }

body {
  font-family: 'Roboto';
  padding-top: 55px; }

img {
  margin-bottom: 2px;
  max-width: 100%; }

hr {
  margin: 10px 0; }

a {
  font-size: 12px;
  color: #567c07; }
  a:hover {
    color: #567c07;
    text-decoration: underline; }

iframe {
  max-height: 600px; }

.img-thumbnail {
  object-fit: cover;
  min-height: 100px; }

.dropdown .open a {
  background: blue; }

.affix {
  top: 70px;
  bottom: 20px; }

.affix-bottom {
  position: absolute; }

footer {
  color: #fff;
  width: 100%;
  padding: 15px;
  background: #567c07;
  margin-top: 20px; }
  footer a {
    color: #fff; }
    footer a:hover {
      color: #fff;
      text-decoration: underline; }

.sidebar {
  height: 1000px; }

.lead {
  font-size: 13px; }

a:not([href]):not([tabindex]) {
  color: #a3a2a2; }

.btn-primary {
  color: #fff; }

.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.form-control {
  margin-bottom: 20px; }

.panel-heading h1 {
  font-size: 16px;
  font-weight: normal;
  margin: 0; }

.portfolio-item {
  margin-bottom: 30px; }

.clear {
  clear: both; }

.upper-bar {
  width: 100%;
  min-height: 30px;
  background: #b1f329;
  padding: 5px;
  line-height: 35px; }
  .upper-bar a {
    color: #567c07;
    padding-right: 15px; }
  .upper-bar span a {
    padding-left: 0px;
    padding-right: 25px; }

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover; }

.cat_pc_modifier {
  margin: -15px; }

.nav-img {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  object-fit: cover; }

.comment-img {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  object-fit: cover; }

.topic-img {
  width: 100%;
  height: 400px;
  object-fit: cover; }

.other-img {
  width: 100%;
  height: 275px;
  object-fit: cover; }

.video-img {
  font-size: 50px;
  width: 100%;
  height: 400px;
  line-height: 400px;
  text-align: center; }

.video-other-img {
  font-size: 50px;
  width: 100%;
  height: 275px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 275px;
  text-align: center; }

.scrolled-header {
  background: #272a32;
  color: white; }
  .scrolled-header .navbar-inverse .navbar-nav > .open > a, .scrolled-header .navbar-inverse .navbar-nav > .open > a:focus, .scrolled-header .navbar-inverse .navbar-nav > .open > a:hover {
    color: #fff;
    background-color: transparent; }
  .scrolled-header .navbar-inverse .navbar-nav > li > a {
    color: #fff;
    font-size: 13px; }
    .scrolled-header .navbar-inverse .navbar-nav > li > a:hover {
      text-decoration: underline; }
  .scrolled-header--techhity {
    background: white; }

.navbar-inverse .navbar-nav .navbar-techhity > .open > a, .navbar-inverse .navbar-nav .navbar-techhity > .open > a:focus, .navbar-inverse .navbar-nav .navbar-techhity > .open > a:hover {
  color: white;
  background-color: white; }

.open .dropdown-menu li > a:hover {
  background: transparent; }

.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d; }
  .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:active, .navbar-inverse .navbar-nav > li > a:focus {
    color: #fff; }

.techhity .navbar-inverse .navbar-nav > .open > a, .techhity .navbar-inverse .navbar-nav > .open > a:focus, .techhity .navbar-inverse .navbar-nav > .open > a:hover {
  color: #000;
  background-color: #fff; }

.techhity .navbar-inverse .navbar-nav > li > a:hover, .techhity .navbar-inverse .navbar-nav > li > a:active, .techhity .navbar-inverse .navbar-nav > li > a:focus {
  color: #171717; }

.other-page {
  margin-top: -60px; }
  .other-page .navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 60px; }
    .other-page .navbar-nav > li > a:hover {
      color: #a3a2a2; }
  .other-page .content {
    margin-top: 130px;
    min-height: 640px; }
  .other-page .navbar-fixed-top {
    min-height: 60px; }

.navbar-inverse {
  background-color: #fff;
  border: 0px; }

.navbar-brand {
  width: 195px; }

.navbar-center {
  margin-left: 40px; }

.dropdown-menu {
  border: 0px; }

.navbar-fixed-top {
  min-height: 80px; }

.navbar-nav > li > a {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px; }
  .navbar-nav > li > a:hover {
    color: #a3a2a2; }

.navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
  color: #a3a2a2;
  text-decoration: none; }

.dropdown-menu > li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #a3a2a2;
  white-space: nowrap; }

.dropdown-menu > li > b {
  color: black;
  padding: 10px 20px; }

.navbar-nav > li > .dropdown-menu {
  padding-top: 10px; }

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555; }

.navbar-form {
  padding: 0px 15px;
  margin-top: 0px;
  margin-right: -15px;
  margin-bottom: 0px;
  margin-left: -15px; }

.down-bar {
  font-size: 12px;
  width: 100%;
  min-height: 30px;
  background: #e8e8e8;
  color: #a3a2a2;
  padding: 10px;
  border: 1px solid #e8e8e8; }

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 17px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: #000;
  border: 0px;
  border-radius: 4px; }

.content {
  margin-top: 130px;
  min-height: 470px; }

.main-article {
  margin-bottom: 20px; }
  .main-article h1 {
    margin: 0px; }
    .main-article h1 a {
      font-size: 20px;
      font-weight: bold;
      margin: 0; }
  .main-article p.lead {
    font-size: 12px;
    margin-bottom: 0px; }
    .main-article p.lead a.category {
      color: #000;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0px; }
  .main-article hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee; }

.article-other {
  min-height: 465px; }
  .article-other h3 {
    margin-bottom: 0px;
    margin-top: 0; }
    .article-other h3 a {
      font-size: 18px;
      font-weight: bold;
      color: #567c07; }
  .article-other p {
    margin: 8px 0 0px; }
    .article-other p.footer {
      font-size: 13px;
      margin-bottom: 5px;
      margin-top: 0;
      background: #f6f6f6;
      padding: 10px 20px; }
    .article-other p.lead {
      font-size: 13px;
      margin-bottom: 5px;
      margin-top: 0; }
    .article-other p.category {
      font-size: 13px;
      font-weight: normal;
      margin-bottom: 0px; }
    .article-other p.content1 {
      min-height: 100px; }
  .article-other hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee; }
  .article-other .image {
    width: 100%;
    height: 300px;
    object-fit: cover; }

.most-readed-articles .most-readed-articles__header {
  font-size: 15px;
  color: #567c07;
  width: 100%;
  padding: 15px 15px 10px;
  background: #b1f329;
  border-bottom: 4px solid #567c07; }

.most-readed-articles .most-readed-articles__body {
  padding: 0px 10px 10px; }
  .most-readed-articles .most-readed-articles__body a {
    font-size: 16px;
    color: #567c07;
    border-left: 2px solid transparent; }
  .most-readed-articles .most-readed-articles__body ul {
    list-style: none;
    display: inline; }
  .most-readed-articles .most-readed-articles__body li {
    border-left: 2px solid #e8e8e8;
    padding: 10px 10px;
    margin-top: 10px; }
    .most-readed-articles .most-readed-articles__body li:hover {
      border-left: 2px solid #567c07; }

.newest-articles {
  margin: 0px 0; }
  .newest-articles .newest-articles__header {
    font-size: 17px;
    color: #567c07;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #e8e8e8; }
  .newest-articles p.lead {
    font-size: 12px;
    margin-bottom: 3px; }
    .newest-articles p.lead a.category {
      color: #000;
      font-size: 13px;
      font-weight: normal;
      margin-bottom: 0px; }

.newest-news .newest-news__header {
  font-size: 17px;
  color: #567c07;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e8e8e8; }

.newest-news .newest-news__body ul {
  list-style: none;
  margin-top: 10px;
  padding-left: 3px; }
  .newest-news .newest-news__body ul li {
    padding: 10px;
    border-left: 2px solid transparent; }
    .newest-news .newest-news__body ul li:hover {
      border-left: 2px solid #567c07; }
    .newest-news .newest-news__body ul li a {
      font-size: 14px;
      border-left: 2px solid transparent; }

.forum-bar {
  margin-top: 20px; }
  .forum-bar .forum-bar__header {
    font-size: 17px;
    color: white;
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #567c07; }
    .forum-bar .forum-bar__header--white {
      font-size: 17px;
      color: #567c07;
      background: transparent;
      border: 1px solid #567c07;
      margin: 10px 0;
      padding: 10px 15px; }
  .forum-bar .forum-bar__body {
    margin-bottom: 20px; }

.pull-right > .dropdown-menu {
  right: auto;
  left: auto; }

.srch-button {
  border-radius: 0px; }

.watermark {
  margin-bottom: 20px; }

.no-results {
  width: 100%;
  text-align: center;
  margin: 40px 0; }
  .no-results h4 {
    font-size: 13px;
    font-weight: normal; }

@media (max-width: 767px) {
  .navbar-nav > li > a {
    color: #a3a2a2;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px; }
  :hover {
    color: #000; }
  .other-page {
    margin-top: -105px; }
    .other-page .navbar-fixed-top {
      min-height: 60px; }
    .other-page .navbar-header {
      height: 50px; }
    .other-page .navbar-inverse .navbar-collapse, .other-page .navbar-inverse .navbar-form {
      background: #b1f329;
      border-bottom: 1px solid #b1f329;
      border-color: #b1f329;
      margin-top: 0px; }
    .other-page .navbar-fixed-top {
      min-height: 60px; }
  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: #000;
    background-color: #b1f329; }
  .navbar-brand {
    width: 195px;
    padding: 8px 15px; }
  .navbar-header {
    height: 80px; }
  .navbar-left, .navbar-right {
    margin-left: 20px; }
  .navbar-inverse .navbar-nav > li > a {
    color: #000;
    font-weight: bold; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #000; }
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    background: #b1f329;
    border-bottom: 1px solid #567c07;
    border-color: #567c07;
    margin-top: 20px; }
  .content {
    margin-top: 30px; } }

@media screen and (max-width: 768px) {
  .topic-img {
    width: 100%;
    height: 200px;
    object-fit: cover; }
  .other-img {
    width: 100%;
    height: 200px;
    object-fit: cover; }
  .article-other {
    min-height: 425px; }
  .article-other p.content1 {
    min-height: 135px; }
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 5px;
    padding-left: 5px; }
  .main-article {
    margin-bottom: 20px; }
    .main-article h1 {
      padding-left: 10px; }
    .main-article p {
      padding-left: 10px; }
  .main-article p.lead {
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px; }
  .article-other h3 {
    padding-left: 10px; }
  .article-other p {
    padding-left: 10px;
    padding-right: 10px; }
  .other-page .navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 40px;
    font-size: 13px; }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 9px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: #000;
    border: 0px;
    border-radius: 4px; }
  .other-page .content {
    margin-top: 115px;
    min-height: 640px; }
  .cat_pc_modifier {
    margin: 0 !important; } }

/*==============================*/
/*====== siderbar user profile =====*/
/*==============================*/
.blog {
  /*==============================*/
  /*====== Social Profile css =====*/
  /*==============================*/
  /*==============================*/
  /*====== Recently connected  heading =====*/
  /*==============================*/ }
  .blog .nav > li > a.userdd {
    padding: 5px 15px; }
  .blog p {
    color: #000;
    margin-bottom: 8px; }
    .blog p.lead {
      font-size: 12px;
      margin-bottom: 5px;
      padding-top: 0; }
      .blog p.lead a.blog-title {
        padding: 5px 5px;
        font-size: 30px; }
      .blog p.lead a.category {
        color: #000;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 8px; }
  .blog .blog-title {
    padding: 5px 5px;
    font-size: 30px; }
    .blog .blog-title small {
      font-size: 12px; }
  .blog .userprofile {
    width: 100%;
    float: left;
    clear: both;
    margin: 10px auto; }
  .blog .userprofile .userpic {
    height: 130px;
    width: 130px;
    clear: both;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative; }
  .blog .userprofile .userpic .userpicimg {
    height: auto;
    width: 100%;
    border-radius: 100%; }
  .blog .username {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .blog .username + p {
    color: #607d8b;
    font-size: 13px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .blog .settingbtn {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15); }
  .blog .userprofile.small {
    width: auto;
    clear: both;
    margin: 0px auto; }
  .blog .userprofile.small .userpic {
    height: 40px;
    width: 40px;
    margin: 0 10px 0 0;
    display: block;
    border-radius: 100%;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    float: left; }
  .blog .userprofile.small .textcontainer {
    float: left;
    max-width: 100px;
    padding: 0; }
  .blog .userprofile.small .userpic .userpicimg {
    min-height: 100%;
    width: 100%;
    border-radius: 100%; }
  .blog .userprofile.small .username {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin: 0px;
    float: left;
    width: 100%; }
  .blog .userprofile.small .username + p {
    color: #607d8b;
    font-size: 13px;
    float: left;
    width: 100%;
    margin: 0; }
  .blog .countlist h3 {
    margin: 0;
    font-weight: 400;
    line-height: 28px; }
  .blog .countlist {
    text-transform: uppercase;
    padding: 0 0px 0 45px; }
  .blog .countlist li {
    padding: 15px 50px 15px 0;
    font-size: 14px; }
  .blog .countlist li small {
    font-size: 12px;
    margin: 0; }
  .blog .countlist a {
    color: #fff; }
  .blog .followbtn {
    float: right;
    margin: 22px 10px; }
  .blog .userprofile.social {
    background: url(http://www.bootdey.com/img/Content/flores-amarillas-wallpaper.jpeg) no-repeat center center;
    background-size: 100%;
    height: 265px;
    padding: 50px 0;
    margin: 0; }
  .blog .userprofile.social .username {
    color: #ffffff; }
  .blog .userprofile.social .username + p {
    color: #ffffff;
    opacity: 0.8; }
  .blog .postbtn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9; }
  .blog .status-upload {
    width: 100%;
    float: left;
    margin-bottom: 15px; }
  .blog .posttimeline .panel {
    margin-bottom: 15px;
    padding-bottom: 20px; }
  .blog .commentsblock {
    background: #f8f9fb; }
  .blog .nopaddingbtm {
    margin-bottom: 0; }
  .blog .memberblock {
    width: 100%;
    float: left;
    clear: both;
    margin-bottom: 15px; }
  .blog .member {
    width: 24%;
    float: left;
    margin: 2px 1% 2px 0;
    background: #ffffff;
    border: 1px solid #d8d0c3;
    padding: 3px;
    position: relative;
    overflow: hidden; }
  .blog .memmbername {
    position: absolute;
    bottom: -30px;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    line-height: 30px;
    padding: 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 11px;
    transition: 0.5s ease all; }
  .blog .member:hover .memmbername {
    bottom: 0; }
  .blog .member img {
    width: 100%;
    transition: 0.5s ease all; }
  .blog .member:hover img {
    opacity: 0.8;
    transform: scale(1.2); }
  .blog .panel-default > .panel-heading {
    color: #567c07;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    padding: 20px;
    border-radius: 0;
    border-color: #e1eaef; }
    .blog .panel-default > .panel-heading.profile {
      color: #333;
      font-size: 20px;
      font-weight: bold; }
  .blog .panel-body .page-subtitle {
    padding: 5px 20px; }
  .blog .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429; }
  .blog .page-header.small {
    position: relative;
    line-height: 22px;
    font-weight: 400;
    font-size: 20px; }
  .blog .favorite i {
    color: #eb3147; }
  .blog .btn i {
    font-size: 17px; }
  .blog .panel {
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
    transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    margin-bottom: 35px;
    border-radius: 0px;
    position: relative;
    border: 0;
    display: inline-block;
    width: 100%; }
  .blog .panel-footer {
    padding: 10px 15px;
    background-color: #ffffff;
    border-top: 1px solid #eef2f4;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: #607d8b; }
  .blog .panel-blue {
    color: #ffffff;
    background-color: #03A9F4; }
  .blog .panel-red.userlist .username, .blog .panel-green.userlist .username, .blog .panel-yellow.userlist .username, .blog .panel-blue.userlist .username {
    color: #ffffff; }
  .blog .panel-red.userlist p, .blog .panel-green.userlist p, .blog .panel-yellow.userlist p, .blog .panel-blue.userlist p {
    color: rgba(255, 255, 255, 0.8); }
  .blog .panel-red.userlist p a, .blog .panel-green.userlist p a, .blog .panel-yellow.userlist p a, .blog .panel-blue.userlist p a {
    color: rgba(255, 255, 255, 0.8); }
  .blog .progress-bar-success, .blog .status.active, .blog .panel-green, .blog .panel-green > .panel-heading, .blog .btn-success, .blog .fc-event, .blog .badge.green, .blog .event_green {
    color: white;
    background-color: #8BC34A; }
  .blog .progress-bar-warning, .blog .panel-yellow, .blog .status.pending, .blog .panel-yellow > .panel-heading, .blog .btn-warning, .blog .fc-unthemed .fc-today, .blog .badge.yellow, .blog .event_yellow {
    color: white;
    background-color: #FFC107; }
  .blog .progress-bar-danger, .blog .panel-red, .blog .status.inactive, .blog .panel-red > .panel-heading, .blog .btn-danger, .blog .badge.red, .blog .event_red {
    color: white;
    background-color: #F44336; }
  .blog .media-object {
    width: 100%;
    height: 320px;
    object-fit: cover;
    margin: 10px 0; }
  .blog .media:first-child {
    margin-top: 15px; }
  .blog .media-object {
    display: block; }
  .blog .media-body {
    margin-left: 20px; }
    .blog .media-body h4 {
      font-size: 18px;
      font-weight: bold; }
  .blog .dotbtn {
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
    line-height: 40px;
    vertical-align: middle;
    padding: 0;
    margin-right: -15px; }
  .blog .dots {
    height: 4px;
    width: 4px;
    position: relative;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin: 0 auto; }
  .blog .dots:after, .blog .dots:before {
    content: " ";
    height: 4px;
    width: 4px;
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    top: -7px;
    left: 0; }
  .blog .dots:after {
    content: " ";
    top: auto;
    bottom: -7px;
    left: 0; }
  .blog .photolist img {
    width: 100%; }
  .blog .photolist {
    background: #e1eaef;
    padding-top: 15px;
    padding-bottom: 15px; }
  .blog .profilegallery .grid-item a {
    height: 100%;
    display: block; }
  .blog .grid a {
    width: 100%;
    display: block;
    float: left; }
  .blog .media-body {
    color: #607D8B;
    overflow: visible; }

@media (max-width: 767px) {
  .blog .userprofile.social {
    background: url(http://www.bootdey.com/img/Content/flores-amarillas-wallpaper.jpeg) no-repeat center center;
    background-size: 100%;
    height: 125px;
    padding: 50px 0;
    margin: 0; }
  .blog .followbtn {
    float: left;
    margin: 12px 10px; }
  .blog .countlist li {
    padding: 15px 15px 15px 0;
    font-size: 14px; } }

.article a {
  font-family: 'Roboto';
  font-size: 14px; }

.article h1 {
  margin: 10px 2px 0 2px; }

.article p {
  margin: 0; }
  .article p a.category {
    font-size: 16px; }

.article .article_img {
  max-width: 100%;
  margin-top: 12px; }

.article .media-body h5 {
  margin: 0; }

.article .media-body small {
  font-size: 11px;
  color: #b1b1b1; }
  .article .media-body small a {
    font-size: 14px; }

.article .media-body p {
  margin: 0; }

.article .articles_widget h2 {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin: 15px 0px; }

.article .articles_widget h3 {
  margin: 10px; }
  .article .articles_widget h3 a {
    font-size: 16px; }

.article .articles_widget .noviewed_img {
  width: 100%;
  height: 200px;
  object-fit: cover; }

.article .articles_widget .noviewed_video {
  width: 100%;
  height: 200px;
  line-height: 200px;
  text-align: center; }

.article .articles_widget .thumb_article {
  min-height: 290px;
  margin: -5px; }

@media screen and (max-width: 768px) {
  .article .topic-img {
    width: 100%;
    height: 200px;
    object-fit: cover; }
  .article .article_mobile {
    margin: 0 -13px; }
    .article .article_mobile h1 {
      padding-right: 10px;
      padding-left: 10px; }
    .article .article_mobile p {
      padding-right: 10px;
      padding-left: 10px; } }

.news h1 {
  margin: 10px 0 0; }

.news p {
  margin: 2px 0px; }

.comments {
  margin-bottom: 40px; }
  .comments .single-comment {
    margin-top: 40px; }
  .comments hr {
    margin-top: 10px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #eee; }
  .comments .card {
    margin: 30px 0; }
  .comments .media-heading a {
    color: #6e6e6e;
    font-size: 12px;
    font-weight: 300; }
  .comments .media-heading span {
    color: #fff;
    font-size: 12px; }
  .comments .media-heading .edit-button {
    padding-left: 15px; }
  .comments .answer .media-body h6 {
    font-size: 14px;
    margin: 0; }
  .comments .answer .media-body small {
    font-size: 11px;
    color: #b1b1b1; }
    .comments .answer .media-body small a {
      font-size: 12px; }
  .comments .answer .media-body p {
    font-size: 12px;
    margin: 0; }
  .comments .answer .media-body .actions a {
    font-size: 13px; }
  .comments .answer .media-heading span {
    color: #fff;
    font-weight: 300; }
  .comments .answer .media-heading a {
    color: #c9c9c9;
    font-size: 12px;
    font-weight: normal; }

ul.discussions {
  list-style: none;
  margin: 0px;
  padding: 0px;
  background: #fff; }

ul.discussions li a.discussion_list, ul.discussions li .chatter_posts {
  background: #ffffff;
  padding: 5px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  border-top: 1px solid #f1fcff;
  text-decoration: none;
  display: block;
  position: relative; }
  ul.discussions li a.discussion_list:hover, ul.discussions li .chatter_posts:hover {
    background: #f9f9f9; }

.chatter_avatar {
  float: left;
  margin: 3px;
  margin-right: 15px;
  position: absolute;
  left: 10px; }

ul.discussions li a.discussion_list .chatter_middle, ul.discussions li .chatter_posts .chatter_middle {
  float: left;
  margin-left: 80px;
  margin-right: 90px; }

ul.discussions li a.discussion_list .chatter_middle h3.chatter_middle_title, ul.discussions li .chatter_posts .chatter_middle h3.chatter_middle_title {
  margin: 0px;
  padding: 10px 0 0;
  color: #111;
  transition: color 0.3s ease;
  font-size: 15px;
  font-weight: bold;
  /* margin-top: 5px; */
  min-height: 24px; }

ul.discussions li a.discussion_list .chatter_middle span.chatter_middle_details, ul.discussions li .chatter_posts .chatter_middle span.chatter_middle_details {
  font-size: 10px;
  color: #9DADC4;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease; }

ul.discussions li a.discussion_list .chatter_right, ul.discussions li .chatter_posts .chatter_right {
  float: right;
  right: 25px;
  text-align: right;
  position: absolute; }

ul.discussions li a.discussion_list .chatter_clear, ul.discussions li .chatter_posts .chatter_clear {
  clear: both; }

.chatter_avatar span.chatter_avatar_circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #567c07;
  display: inline-block;
  border-radius: 30px;
  color: #fff;
  font-size: 20px; }

ul.discussions li a.discussion_list .chatter_middle h3.chatter_middle_title .chatter_cat, #chatter ul.discussions li .chatter_posts .chatter_middle h3.chatter_middle_title .chatter_cat {
  background: #ccc;
  border-radius: 30px;
  font-weight: bold;
  font-size: 10px;
  padding: 3px 7px;
  display: inline;
  color: #fff;
  position: relative;
  top: -2px; }

.chatter-bubble:before {
  content: "\61"; }

ul.discussions li a.discussion_list .chatter_right .chatter_count, #chatter ul.discussions li .chatter_posts .chatter_right .chatter_count {
  padding: 8px 20px;
  border-radius: 3px;
  margin-top: 0px;
  font-size: 20px;
  color: #9DADB4;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  font-family: Arial, Helvetica, Times, Courier; }

ul.discussions li a.discussion_list .chatter_right .chatter_count i, #chatter ul.discussions li .chatter_posts .chatter_right .chatter_count i {
  position: relative;
  top: 2px; }

[class^="chatter-"]:before, [class*=" chatter-"]:before {
  font-family: "chatter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
