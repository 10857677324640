
body {
  font-family: 'Roboto';
  padding-top: 55px;
}

img {
  margin-bottom: 2px;
  max-width: 100%;
}

hr {
  margin:10px 0;
}

a {
  font-size: 12px;
  color:$primary-color;
  &:hover {
    color:$primary-color;
    text-decoration: underline;
  }
}

iframe {
  max-height: 600px;
}

.img-thumbnail {
  object-fit: cover;
  min-height: 100px;
}

.dropdown .open a {
  background: blue;
}

.affix {
  top: 70px;
  bottom: 20px;
}

.affix-bottom {
  position: absolute;
}

footer {
  color: #fff;
  width:100%;
  padding:15px;
  background: $primary-color;
  margin-top: 20px;
  a {
    color:#fff;
    &:hover {
      color:#fff;
      text-decoration: underline;
    }
  }
}

.sidebar {
  height: 1000px;
}

.lead{
  font-size: 13px;
}

a:not([href]):not([tabindex]) {
  color:$darker-silver-color;
}

.btn-primary {
  color:#fff;
}

.carousel-item {
  height: 65vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-control {
  margin-bottom: 20px;
}

.panel-heading {
  h1 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.portfolio-item {
  margin-bottom: 30px;
}

.clear {
  clear: both;
}

.upper-bar {
  width:100%;
  min-height: 30px;
  background: $secondary-color;
  padding:5px;
  line-height: 35px;

  a {
    color:$primary-color;
    padding-right: 15px;
  }
  span {
    a {
      padding-left: 0px;
      padding-right:25px;
    }
  }
}

.avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cat_pc_modifier {
  margin: -15px;
}

.nav-img {
  margin-right:10px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.comment-img {
  margin-right:10px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.topic-img {
  width:100%;
  height:400px;
  object-fit: cover;
}

.other-img {
  width:100%;
  height:275px;
  object-fit: cover;
}

.video-img {
  font-size: 50px;
  width:100%;
  height:400px;
  line-height: 400px;
  text-align: center;
}

.video-other-img {
  font-size: 50px;
  width:100%;
  height:275px;
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  line-height: 275px;
  text-align: center;
}

.scrolled-header {
  background: #272a32;
  color: white;


  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: #fff;
    background-color: transparent;
  }

  .navbar-inverse .navbar-nav > li > a {
    color: #fff;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }
  }

  &--techhity {
    background: white;
  }
}

.navbar-inverse .navbar-nav .navbar-techhity > .open > a, .navbar-inverse .navbar-nav .navbar-techhity > .open > a:focus, .navbar-inverse .navbar-nav .navbar-techhity > .open > a:hover {
  color: white;
  background-color: white;
}

.open .dropdown-menu {
  li > a:hover {
    background: transparent;
  }
}

.navbar-inverse .navbar-nav>li>a {
  color: #9d9d9d;
  &:hover, &:active, &:focus {
    color:#fff;
  }
}

.techhity {

  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: #000;
    background-color: #fff;
  }

  .navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:active, .navbar-inverse .navbar-nav > li > a:focus {
    color: #171717;
  }
}

.other-page {
  margin-top: -60px;
  .navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 60px;
    &:hover {
      color:$darker-silver-color;
    }
  }

  .content {
    margin-top:130px;
    min-height: 640px;
  }

  .navbar-fixed-top {
    min-height: 60px;
  }
}

.navbar-inverse {
  background-color: #fff;
  border:0px;
}

.navbar-brand {
width:195px;
}

.navbar-center {
  margin-left:40px;
}

.dropdown-menu {
  border:0px;
}

.navbar-fixed-top {
  min-height: 80px;
}

.navbar-nav > li > a {
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
  &:hover {
    color:$darker-silver-color;
  }
}



.navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
  color:$darker-silver-color;
  text-decoration: none;
}

.dropdown-menu>li>a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: $darker-silver-color;
  white-space: nowrap;
}

.dropdown-menu>li>b {
  color: black;
  padding:10px 20px;
}

.navbar-nav>li>.dropdown-menu {
  padding-top: 10px;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
}

.navbar-form {
  padding: 0px 15px;
  margin-top: 0px;
  margin-right: -15px;
  margin-bottom: 0px;
  margin-left: -15px;
}


.down-bar {
  font-size: 12px;
  width:100%;
  min-height:30px;
  background: #e8e8e8;
  color:$darker-silver-color;
  padding: 10px;
  border: 1px solid $silver-color;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 17px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: #000;
  border: 0px;
  border-radius: 4px;
}

.content {
  margin-top:130px;
  min-height: 470px;
}


.main-article {
  margin-bottom:20px;

  h1 {
    margin:0px;
    a {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
  }


  p {
    &.lead{
      font-size:12px;
      margin-bottom: 0px;

      a {
        &.category {
          color: $black-color;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 0px;
        }
      }
    }
  }
  hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee;
  }

}

.article-other {
  min-height:465px;
  h3 {
    margin-bottom: 0px;
    margin-top: 0;
    a {
      font-size: 18px;
      font-weight: bold;
      color: $primary-color;
    }
  }

  p {
    margin: 8px 0 0px;

    &.footer {
      font-size:13px;
      margin-bottom: 5px;
      margin-top: 0;
      background: #f6f6f6;
      padding: 10px 20px;
    }

    &.lead{
      font-size:13px;
      margin-bottom: 5px;
      margin-top: 0;
    }

    &.category {
      font-size:13px;
      font-weight: normal;
      margin-bottom: 0px;
    }

    &.content1 {
    min-height:100px;
    }

  }
  hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

}

.most-readed-articles {
  .most-readed-articles__header {
    font-size:15px;
    color: $primary-color;
    width:100%;
    padding: 15px 15px 10px;
    background: $secondary-color;
    border-bottom: 4px solid $primary-color;
  }

  .most-readed-articles__body {
    padding:0px 10px 10px;

    a {
      font-size:16px;
      color:$primary-color;
      border-left: 2px solid transparent;
    }
    ul {
      list-style: none;
      display: inline;
    }

    li {
      border-left: 2px solid $silver-color;
      padding:10px 10px;
      margin-top:10px;
      &:hover {
        border-left: 2px solid $primary-color;
      }
    }
  }
}

.newest-articles {
  margin: 0px 0;
  .newest-articles__header {
    font-size:17px;
    color:$primary-color;
    width:100%;
    padding:10px 15px;
    border: 1px solid $silver-color;
  }

  p {
    &.lead{
      font-size:12px;
      margin-bottom: 3px;

      a {
        &.category {
          color: $black-color;
          font-size: 13px;
          font-weight: normal;
          margin-bottom: 0px;
        }
      }
    }
  }

}

.newest-news {
  .newest-news__header {
    font-size:17px;
    color:$primary-color;
    width:100%;
    padding:10px 15px;
    border: 1px solid $silver-color;
  }
  .newest-news__body {
    ul {
      list-style: none;
      margin-top: 10px;
      padding-left: 3px;
      li {
        padding: 10px;
        border-left: 2px solid transparent;
        &:hover {
          border-left: 2px solid $primary-color;
        }
        a {
          font-size: 14px;
          border-left: 2px solid transparent;
        }
      }
    }
  }
}

.forum-bar {
  margin-top: 20px;
  .forum-bar__header {
    font-size:17px;
    color:white;
    width:100%;
    padding:10px;
    text-align: center;
    background: $primary-color;
    &--white {
      font-size: 17px;
      color: $primary-color;
      background: transparent;
      border: 1px solid $primary-color;
      margin:10px 0;
      padding: 10px 15px;
    }
  }

  .forum-bar__body {
    margin-bottom: 20px;
  }
}

.pull-right>.dropdown-menu {
  right: auto;
  left: auto;
}

.srch-button {
  border-radius: 0px;
}

.watermark {
  margin-bottom: 20px;
}

.no-results {
  width: 100%;
  text-align: center;
  margin:40px 0;
  h4 {
    font-size:13px;
    font-weight: normal;
  }
}


@media (max-width: 767px) {
  .navbar-nav > li > a {
    color: $darker-silver-color;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;}

  &:hover {
    color:$black-color;
  }

  .other-page {
    margin-top: -105px;


    .navbar-fixed-top {
      min-height: 60px;
    }
    .navbar-header {
      height:50px;
    }

    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
      background: $secondary-color;
      border-bottom: 1px solid $secondary-color;
      border-color: $secondary-color;
      margin-top: 0px;
    }

    .navbar-fixed-top {
      min-height: 60px;
    }
  }

  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: $black-color;
    background-color: $secondary-color;
  }

  .navbar-brand {
    width:195px;
    padding: 8px 15px;
  }

  .navbar-header {
    height:80px;
  }

  .navbar-left, .navbar-right {
    margin-left:20px;
  }

  .navbar-inverse .navbar-nav > li > a {
    color:$black-color;
    font-weight: bold;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: $black-color;
  }

  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    background: $secondary-color;
    border-bottom: 1px solid $primary-color;
    border-color: $primary-color;
    margin-top: 20px;
  }

  .content {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .topic-img {
    width:100%;
    height:200px;
    object-fit: cover;
  }

  .other-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .article-other {
    min-height: 425px;
  }

  .article-other p.content1 {
    min-height: 135px;
  }

  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 5px;
    padding-left: 5px;
  }

  .main-article {
    margin-bottom: 20px;
    h1 {
      padding-left: 10px;
    }
    p {
      padding-left: 10px;
    }
  }

  .main-article p.lead {
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .article-other {
    h3 {
      padding-left: 10px;
    }
    p {
        padding-left: 10px;
        padding-right: 10px;
    }
  }

  .other-page .navbar-nav > li > a {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 40px;
    font-size: 13px;
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 9px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: #000;
    border: 0px;
    border-radius: 4px;
  }

  .other-page .content {
    margin-top: 115px;
    min-height: 640px;
  }

  .cat_pc_modifier {
    margin: 0 !important;
  }
}
